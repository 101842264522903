@supports (display: flex) {
  .sv-row {
    display: flex;
    flex-wrap: wrap;
  }

  //TODO need for vuejs because it contains additional div in the row
  .sv-vue-row-additional-div {
    display: flex;
    flex-wrap: wrap;
    flex-basis: 100%;
    width: 100%;
  }
}

// styles only for IE https://stackoverflow.com/questions/11173106/apply-style-only-on-ie
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .sv-row > .sv-row__panel,
  .sv-row__question:not(:last-child) {
    float: left;
    @media only screen and (max-width: 600px) {
      padding-bottom: 2.5em;
      float: none;
    }
  }
  [dir="rtl"],
  [style*="direction:rtl"] {
    .sv-row__question:not(:last-child) {
      float: right;
    }
  }

  .sv-row__question--small:only-child {
    @media only screen and (max-width: 6000px) {
      max-width: 3000px;
    }
    @media only screen and (max-width: 3000px) {
      max-width: 1200px;
    }
    @media only screen and (max-width: 2000px) {
      max-width: 700px;
    }
    @media only screen and (max-width: 1000px) {
      max-width: 500px;
    }
    @media only screen and (max-width: 500px) {
      max-width: 300px;
    }
  }
  .sv-row > .sv-row__panel,
  .sv-row__question {
    @media only screen and (max-width: 600px) {
      width: 100% !important;
      padding-right: 0 !important;
    }
  }
}

.sv-row > .sv-row__panel,
.sv-row__question {
  vertical-align: top;
  white-space: normal;
}

// https://github.com/surveyjs/survey-library/issues/2559
.sv-row__question:first-child:last-child {
  flex: none !important;
}

// .sv-row > .sv-row__panel,
// .sv-row__question:not(:last-child) {
//   float: left;
//   @media only screen and (max-width: 600px) {
//     padding-bottom: 2.5em;
//     float: none;
//   }
// }
// [dir="rtl"],
// [style*="direction:rtl"] {
//   .sv-row__question:not(:last-child) {
//     float: right;
//   }
// }
.sv-row:not(:last-child) {
  padding-bottom: 2.5em;
}
// .sv-row > .sv-row__panel,
// .sv-row__question {
//   @media only screen and (max-width: 600px) {
//     width: 100% !important;
//     padding-right: 0 !important;
//   }
// }
